import Vehicle from "../../../views/pages/vehicles/Vehicle";

const initialState = {
  data: [
    {
      status: "FINISHED",
      driver_detail: {
        name: "fjh",
        phone_number: 9177379064,
      },
      _id: "5e873027676f0113bf5634c2",
      booking_number: "RideFlex-0001",
      customer_detail: { name: "Usman Khan" },
      pickup_address: "36V7+VM Dubai - United Arab Emirates",
      destination_address: "Umm Suqeim St - Dubai - United Arab Emirates",
      pickup_placename: "Spinneys Market Barsha",
      pickup_time: "2020-08-11T13:30:28.000Z",
      service_class: "Green Class",
      currency: "Rs",
      price: 123.488747,
      color: "green",
      distance: "15 KM",
    },
    {
      status: "FINISHED",
      driver_detail: {
        name: "fjh",
        phone_number: 9177379064,
      },
      _id: "5e9055da18cf884aa902b6eb",
      booking_number: "RideFlex-0002",
      customer_detail: { name: "Usman Khan" },
      pickup_address: "36V7+VM Dubai - United Arab Emirates",
      destination_address: "Umm Suqeim St - Dubai - United Arab Emirates",
      pickup_placename: "Spinneys Market Barsha",
      pickup_time: "2020-05-01T01:30:28.000Z",
      service_class: "First Class",
      currency: "Rs",
      price: 137.90784685,
      color: "blue",
      distance: "15 KM",
    },
  ],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  id: "",
  finished_details: "",
  driver_details: "",
  vehicle_details: "",
  heading: "",
  sub_heading: "",
};

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FINISHED":
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
      };
    case "GET_FINISHED_ID":
      return {
        ...state,
        id: action.id,
      };
    case "GET_FINISHED_DETAIL":
      return {
        ...state,
        finished_details: action.finished_details,
        driver_details: action.driver_detail,
        vehicle_details: action.vehicle_detail,
        //offer_price:action.offer_price
      };
    default:
      return state;
  }
};

export default DataListReducer;
