import axios from "axios";
import { DeviceUUID } from 'device-uuid';
const setAuthToken = token => {
  var du = new DeviceUUID().parse();
  var dua = [
      du.language,
      du.os,
      du.cpuCores,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
  ];
  console.log("dua",dua)
  var uuid = du.hashMD5(dua.join(':'));
  if (token) {
    // Apply authorization token to every request if logged in
    axios.defaults.timeout = 60000;
    axios.defaults.headers.common["oauth"] = token;
    axios.defaults.headers.common["device_id"]=uuid;
    axios.defaults.headers.common["device_type"]='WEB';
    axios.defaults.headers.common["device_name"]=du.browser;
    axios.defaults.headers.common["app_type"]='Partner';
    axios.defaults.headers.common["device_version"]=du.version;
    axios.defaults.headers.common["os_name"]=du.os;
    axios.defaults.headers.common["app_version"]="1";
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["oauth"];
  }
};

export default setAuthToken;