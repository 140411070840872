import React, { Component } from "react";
import "../../../assets/scss/components/app-loader.scss"

class DotSpinner extends Component {
  render() {
    return (
      <div class="spinner-dot">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    );
  }
}

export default DotSpinner;