import React from 'react'
import logout from "../../../assets/img/rides/logout.svg"
import "./modelCss.scss";

export default function LogoutModel({ isOpen, close, logutUser, ...props }) {
    return (
        <div>
            {isOpen && (
                <div className="model_block">
                    <div className='row' style={{ justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <div className='col-4'>
                            <div className='model_content_block'>
                                <div className='model_content_block_overlay'>
                                    <img src={logout} />
                                    {/* <div className='close_button'
                                        onClick={(e) => {
                                            close(e)
                                            // refreshCard()
                                        }}
                                    >X */}
                                    {/* </div> */}
                                    {/* {selectedDriverId ? selectedDriverId : null} */}
                                    {/* <div className='model_heading'>{props.bookingTitle}</div> */}
                                    {/* {props.selectModelId} */}
                                    <div className='model_text' style={{ padding: "2rem 0" }}>Are your sure you want to logout?</div>
                                    <div className='button_block' style={{ fontSize: "20px", marginTop: "0.5rem" }}>
                                        <div style={{ padding: "10px 40px", color: "black", border: "1px solid gray" }}
                                            onClick={(e) => {
                                                close(e)
                                                // refreshCard()
                                            }}
                                        >Cancel</div>
                                        <div style={{ padding: "10px 40px" }} onClick={() => { logutUser()}}>Logout</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
