const initialState = {
  card_partner: { total: 0, individual: 0, company: 0 },
  card_customer: { total: 0, totalActive_vehicles: 0 , totalDeactive_vehicles:0 },
  card_bookings: {
    total: 0,
    bookings_not_approved: 0,
    bookings_not_assigned: 0,
    assigned_but_not_accepted: 0,
  },
  card_approval_pending: { total: 0, company:0, individual: 0 },

  revenue_label: [],
  revenue_present: [],
  revenue_past: [],
  revenue_present_earning: 0,
  revenue_past_earning: 0,
  booking_label: [],
  booking_hourly: [],
  booking_on_way_trip: [],
  booking_present_one_way_trip: 0,
  booking_present_hourly: 0,
  booking_past_one_way_trip: 0,
  booking_past_hourly: 0,
  booking_percentage:[]
};

const calculateRevenuePercentage = (present, past) => {
  if (present > past) {
    // console.log("increment",((past-present)*100)/past)
    // this.setState({
      //   percentage_type: "increment",
      //   percentage_value: (((present - past) * 100) / present).toFixed(2),
      // });
      return ["increment", (((present - past) * 100) / present).toFixed(2)];
    } else if (present == past) {
    return ["increment", 0
    // (((present - past) * 100) / present).toFixed(2)
  ];
  } else {
    //console.log("decrement",((present-past)*100)/present)
    // this.setState({
    //   percentage_type: "decrement",
    //   percentage_value: (((past - present) * 100) / past).toFixed(2),
    // });
    return ["decrement", (((past - present) * 100) / past).toFixed(2)];
  }
};

const calculateBookingPercentage = (
  present_one_way,
  present_hourly,
  past_one_way,
  past_hourly
) => {
  const present = present_one_way + present_hourly;
  const past = past_one_way + past_hourly;
  if (present > past) {
    return ["increment", (((present - past) * 100) / present).toFixed(2)];
  } else if (present == past) {
    return ["increment", (((present - past) * 100) / present).toFixed(2)];
  } else {
    return ["decrement", (((past - present) * 100) / past).toFixed(2)];
  }
};



const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_CARDS":
      return {
        ...state,
        card_partner: { total: action.payload?.drivers?.total, individual: action.payload?.drivers?.deactive, company: action.payload?.drivers?.active },
        card_customer: { total: action.payload?.vehicles?.total, totalActive_vehicles: action.payload?.vehicles?.active, totalDeactive_vehicles:action.payload?.vehicles?.deactive },
        card_bookings: {
          total: action.payload?.bookings?.total,
          bookings_not_approved: action.payload?.bookings?.Waiting,
          bookings_not_assigned: action.payload?.bookings?.completed,
          assigned_but_not_accepted: 0,
        },
        card_approval_pending: { total: action.payload?.approved?.total, company: action.payload?.approved?.drivers, individual: action.payload?.approved?.vehicles
        },
      };
      case "GET_DASHBOARD_REVENUE":
      return {
        ...state,
        revenue_label: action.payload?.current?.chartStats?.chartLabels,
        revenue_present: action.payload?.current?.chartStats?.chartValues,
        revenue_past: action.payload?.previous?.chartStats?.chartValues,
        revenue_present_earning:  action.payload?.current?.totalRevenue,
        revenue_past_earning: action.payload?.previous?.totalRevenue,
        revenue_percentage: calculateRevenuePercentage(
          action.payload?.current?.totalRevenue,
          action.payload?.previous?.totalRevenue
        ),
      };
    case "GET_DASHBOARD_BOOKINGS":
      return {
        ...state,
        booking_label: action.payload?.current?.hourly?.chartLabels,
        booking_hourly: action.payload?.current?.hourly?.chartValues,
        booking_on_way_trip: action.payload?.current?.onWay?.chartValues,
        booking_present_one_way_trip: action.payload?.current?.totalOnWay,
        booking_present_hourly: action.payload?.current?.totalHourly,
        booking_past_one_way_trip: action.payload?.previous?.totalOnWay,
        booking_past_hourly: action.payload?.previous?.totalHourly,
        booking_percentage: calculateBookingPercentage(
          action.payload?.current?.totalOnWay,
          action.payload?.current?.totalHourly,
          action.payload?.previous?.totalOnWay,
          action.payload?.previous?.totalHourly),
      };
    default:
      return state;
  }
};

export default dashboard;
