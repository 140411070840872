import Resturl from '../../../configs/url';

import axios from 'axios';
import {
  loadingSidebarTrue,
  loadingSidebarFalse,
  loadingTrue,
  loadingFalse,
} from '../loading';
import { availableDriver } from '../driver';
import { notification } from '../../../utility/notification';

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params) => {
  return async (dispatch, getState) => {
    if (isEmpty(params)) {
      params = {
        page: 1,
        limit: 4,
        search_data: getState().vehicle.search_data,
      };
    }
    await axios
      .post(Resturl.vehicle_list, params)
      .then((response) => {
        if (response.data.status === 401) {
          localStorage.removeItem('Oauth');
          window.location.href = '/';
        }
        dispatch({
          type: 'GET_VEHICLE',
          data: response.data.vehicles,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_vehicle,
          start: response.data.start,
          end: response.data.end,
          heading: response.data.message,
          sub_heading: response.data.message,
          params,
        });
        dispatch({ type: 'VEHICLE_STATUS_LOADER',payload:{id:'',loading:false} });
      })
      .catch((error) => {
        {
          if (error.code === 'ECONNABORTED') {
            notification("Vehicle","Server not respond.Please try again.","danger")
          }
        }
      });
  };
};

export const availableVehicle = () => {
  return async (dispatch, getState) => {
    await axios
      .post(Resturl.available_vehicle)
      .then((response) => {
        dispatch({
          type: 'GET_AVAILABLE_VEHICLE',
          data: response.data.vehicles,
        });
      })
      .catch((error) => {});
  };
};

export const changeStatus = (value) => {
  console.log(value);
  return async (dispatch, getState) => {
    dispatch({ type: 'VEHICLE_STATUS_LOADER',payload:{id:value.id,loading:true} });
    if (value.working_status === 'ACTIVE') {
      await axios
        .post(Resturl.vehicle_status, {
          vehicle_id: value.id,
          account_status: 'DEACTIVATED',
        })
        .then((response) => {
          notification("Vehicle",response.data.message,"success")
          dispatch({ type: 'VEHICLE_STATUS_DEACTIVATED' });
          dispatch(getData(getState().vehicle.params));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios
        .post(Resturl.vehicle_status, {
          vehicle_id: value.id,
          account_status: 'ACTIVE',
        })
        .then((response) => {
          notification("Vehicle",response.data.message,"success")
          dispatch({
            type: 'VEHICLE_STATUS_ACTIVE',
            data: { id: value.id, status: 'ACTIVE' },
          });
          dispatch(getData(getState().vehicle.params));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const getModel = () => {
  return async (dispatch, getState) => {
    dispatch(availableVehicle());
    dispatch(availableDriver());
    await axios
      .post(Resturl.vehicle_model, {
        location_id: getState().auth.login.values.location_id,
      })
      .then((response) => {
        dispatch({
          type: 'GET_MODEL',
          model: response.data.vehicle_model,
        });
      })
      .catch((error) => {});
  };
};

export const getInitialData = () => {
  return async (dispatch) => {
    await axios
      .post(Resturl.vehicle_list, { limit: 4, page: 1 })
      .then((response) => {
        dispatch({ type: 'GET_ALL_DATA', data: response.data.drivers });
      });
  };
};

export const filterData = (value) => {
  let params = {
    page: 1,
    limit: 4,
    search_data: value,
  };
  return async (dispatch) => {
    await axios.post(Resturl.vehicle_list, params).then((response) => {
      dispatch({
        type: 'FILTER_VEHICLE',
        data: response.data.vehicles,
        totalPages: response.data.total_page,
        totalRecords: response.data.total_vehicle,
        start: response.data.start,
        end: response.data.end,
        heading: response.data.message,
        sub_heading: response.data.message,
        params,
      });
    });
  };
};

export const deleteData = (obj) => {
  return (dispatch) => {
    axios
      .post(Resturl.vehicle_delete, {
        vehicle_id: obj,
      })
      .then((response) => {
        if(response.data.status==1){
          notification("Vehicle",response.data.message,"success")
        
        }
        else{
          notification("Vehicle",response.data.message,"danger")
         
        }

        
        dispatch({ type: 'DELETE_VEHICLE', obj });
      });
  };
};
export const updateVehicle = (obj) => {
  return (dispatch, getState) => {
    axios.post(Resturl.vehicle_edit, obj).then((response) => {
      notification("Vehicle",response.data.message,"success")
 
      dispatch({ type: 'UPDATE_DATA' });
      dispatch(getData());
    });
  };
};

export const addVehicle = (obj) => {
  return async (dispatch) => {
    //let params = getState().dataList.params
    axios
      .post(Resturl.add_vehicle, obj)
      .then((response) => {
        notification("Vehicle",response.data.message,"success")
        dispatch({ type: 'ADD_VEHICLE' });
        dispatch(getData());
      })
      .catch((error) => {
        notification("Vehicle",error.response.data.message,"danger")
      });
  };
};
export const getId = (id) => {
  
  if (id != '') {
    return async (dispatch) => {
      dispatch(loadingSidebarTrue());
      await axios
        .post(Resturl.vehicle_detail, { vehicle_id: id })
        .then((response) => {
          console.log(response)
          dispatch({
            type: 'GET_VEHICLE_ID',
            id: id,
            vehicle_detail: response.data.vehicleDetail,
          });
        })
        .catch((error) => {
         // notification("Vehicle",error.response.data.message,"success")
   
        });
      dispatch(loadingSidebarFalse());
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: 'GET_VEHICLE_ID',
        id: '',
        vehicle_detail: '',
      });
    };
  }
};
