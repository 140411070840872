const initialState = {
  data: [],
  available_drivers: [],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  start: 0,
  end: 0,
  id: '',
  heading: '',
  sub_heading: '',
};
const availableDriver = (val) => {
  const result = [];
  result.push({
    value: 'Unassigned',
    label: 'Unassigned',
    color: '#FFFFFF',
    isFixed: true,
    disabled: 'yes',
  });
  val.map((i) => {
    console.log(i);
    result.push({
      value: `${i._id}`,
      label: `${i.first_name} ${i.last_name}  ${i.mobile_number}`,
      color: '#FFFFFF',
      isFixed: true,
    });
  });
  return result;
};

const determinePopularity = (val) => {
  if (val >= 75) return { popValue: val, color: 'success' };
  else if (val < 75 && val >= 55) return { popValue: val, color: 'primary' };
  else if (val < 55 && val >= 35) return { popValue: val, color: 'warning' };
  else if (val < 35 && val >= 0) return { popValue: val, color: 'danger' };
  else return { popValue: 0, color: 'danger' };
};

const moveIndex = (arr, from, to) => {
  let el = arr[from];
  arr.splice(from, 1);
  arr.splice(to, 0, el);
};

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex((i) => i.id === arr2[0].id) + 1;
    let endIndex = arr.findIndex((i) => i.id === arr2[arr2.length - 1].id) + 1;
    let finalArr = [startIndex, endIndex];
    return (arr3 = finalArr);
  } else {
    let finalArr = [arr.length - parseInt(params.limit), arr.length];
    return (arr3 = finalArr);
  }
};

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DRIVER':
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
        heading:action.heading,
        sub_heading:action.sub_heading
      };
    case 'GET_AVAILABLE_DRIVER':
      return {
        ...state,
        available_drivers: availableDriver(action.data),
      };
      case 'DRIVER_STATUS_LOADER':
        return{
          ...state,
          loading_id:action.payload.id,
          loading:action.payload.loading
        }
    case 'FILTER_DRIVER':
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
        heading:action.heading,
        sub_heading:action.sub_heading
      };
    case 'ADD_DATA':
      let id = state.data.slice(-1)[0].id + 1;
      state.data.push({
        ...action.obj,
        id,
        popularity: determinePopularity(action.obj.popularity),
      });
      moveIndex(
        state.data,
        state.data.findIndex((item) => item.id === id),
        0
      );
      return {
        ...state,
        data: state.data,
        totalRecords: state.allData.length,
        sortIndex: getIndex(state.allData, state.data, state.sortIndex),
      };
    case 'UPDATE_DATA':
      // state.data.find(item => {
      //   if (item.id === action.obj.id) {
      //     let popularity = determinePopularity(action.obj.popularity.popValue);
      //     return Object.assign(item, { ...action.obj, popularity });
      //   } else {
      //     return item;
      //   }
      // });
      return { ...state };
    case 'DELETE_DRIVER':
      // let index = state.data.findIndex(item => item.id === action.obj.id)
      // let updatedData = [...state.data]
      // updatedData.splice(index, 1)
      return {
        ...state,
        //data: updatedData,
        //totalRecords: state.allData.length,
        // sortIndex: getIndex(
        //   state.allData,
        //   state.data,
        //   state.sortIndex,
        //   state.params
        // )
      };
    case 'GET_DRIVER_ID':
      return {
        ...state,
        id: action.id,
      };
    default:
      return state;
  }
};

export default DataListReducer;
