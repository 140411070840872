const urlObj = {
  login_url: '/login',
  uploadimage: '/upload-file',
  validate_auth: '/authentication',

  add_driver: '/add-driver',
  driver_list: '/drivers',
  driver_detail: '/driver-detail',
  driver_edit: '/edit-driver',
  driver_delete: '/delete-driver',
  driver_status: '/driver-status',
  available_driver: '/active-drivers',
  assign_driver:'/assign-driver',

  add_vehicle: '/add-vehicle',
  vehicle_list: '/vehicles',
  vehicle_detail: '/vehicle-detail',
  vehicle_edit: '/vehicle-edit',
  vehicle_delete: '/vehicle-delete',
  vehicle_model: '/vehicle-model',
  vehicle_status: '/vehicle-status',
  available_vehicle: '/active-vehicles',
  assign_vehicle:'/assign-vehicle',
  

  offer_list: '/offers',
  offer_detail: '/offer-detail',
  offer_action: '/offer-action',

  ////////////////Dashboard
  dashboard_info:'dashboard-info',
  revenue_chart:'revenue-chart',
  booking_chart:'booking-chart',
  onboard_doc_upload:'upload-docs',

  compnay_doc :'update-doc',

};

module.exports = urlObj;
