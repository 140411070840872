const messageObj = {
  driver_list_heading: 'Still No any driver registered our system.',
  driver_list_subheading:'Please make your driver, after that you can assign the booking.',
  vehicle_list_heading:"You don't register any vehicle.",
  vehicle_list_subheading:'',
  offer_list_heading: 'No offers available at the moment.',
  offer_list_subheading:'',
  rating_list_heading: 'No one rated at the moment.',
  rating_list_subheading:'',
  
};

module.exports = messageObj;
