import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./index.scss";
import "./@fake-db";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NetworkService from "./utility/network-service";
NetworkService.setupInterceptors(store);

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const LazyApp = lazy(() => import("./App"));

// configureDatabase()

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
        <div className="app-container">
          <ReactNotification />
          <LazyApp />
          <ToastContainer />
        </div>
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
