import React from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"
import Navbar from "./components/navbar/Navbar"
import Footer from "./components/footer/Footer";
import RideFlexLogo from "../assets/img/logo/ride_flex_logo_login.svg";
import ic_login from "../assets/img/logo/ic_login.png";
import "./components/menu/horizontal-menu/horzontalNavebar.scss";

const OnboardingpageLayout = ({ children, ...rest }) => {
  return (
    <div className="horizontal-layout" style={{ paddingTop: "8rem" }}>
      <div className="app-content">
        <Navbar
          horizontal
        />
        <div className="horizontal-menu-wrapper onborading">
          <div className="header-navbar re-nave_manubar_block">
            <div className="navbar-container main-menu-content">
              <ul className="nav navbar-nav" id="main-menu-navigation">
                <div className='re-navemanu_bar_logo'><img src={RideFlexLogo} /></div>
                <div className='re-navemanu_bar_logo onborading_logo'><img src={ic_login} /></div>
              </ul>
            </div>
          </div>
        </div>
        <main className="main" style={{minHeight: "84vh"}}>{children}</main>
      </div>
      {/* <div className="footer_auth">We use cookies. By continuing to use our online service you consent to the use of cookies in accordance with our Cookie Policy.</div> */}
      <Footer />
    </div>

  )
}

export default OnboardingpageLayout
