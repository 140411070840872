const initialState = {
  data: [
    {
      status: 'PENDING',
      driver_detail: {
        name: 'fjh',
        phone_number: 9177379064,
      },
      _id: '5f60c3c02fc1c823de3a171d',
      booking_number: 'RideFlex-0001',
      customer_detail: { name: 'Usman Khan' },
      pickup_address: '36V7+VM Dubai - United Arab Emirates',
      destination_address: 'Umm Suqeim St - Dubai - United Arab Emirates',
      pickup_placename: 'Spinneys Market Barsha',
      pickup_time: '2020-08-11T13:30:28.000Z',
      service_class: 'Green Class',
      currency:"Rs",
      price:123.488747,
      color: 'green',
      distance: '15 KM',
    },
    {
      status: 'PENDING',
      driver_detail: {
        name: 'fjh',
        phone_number: 9177379064,
      },
      _id: '5f60c3c02fc1c823de3a171d',
      booking_number: 'RideFlex-0002',
      customer_detail: { name: 'Usman Khan' },
      pickup_address: '36V7+VM Dubai - United Arab Emirates',
      destination_address: 'Umm Suqeim St - Dubai - United Arab Emirates',
      pickup_placename: 'Spinneys Market Barsha',
      pickup_time: '2020-05-01T01:30:28.000Z',
      service_class: 'First Class',
      currency:"Rs",
      price:137.90784685,
      color: 'blue',
      distance: '15 KM',
    },
  ],
  params: null,
  totalPages: 0,
  totalRecords: 0,
  id: '',
  offer_details: '',
  heading: '',
  sub_heading: '',
};

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OFFER':
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalRecords: action.totalRecords,
        params: action.params,
        start: action.start,
        end: action.end,
      };
    case 'GET_ALL_DATA':
      return {
        ...state,
        // allData: action.data,
        // totalRecords: action.data.length,
        // sortIndex: getIndex(action.data, state.data, state.sortIndex)
      };
    // case "FILTER_DATA":
    //   let value = action.value
    //   let filteredData = []
    //   if (value.length) {
    //     filteredData = state.allData
    //       .filter(item => {
    //         let startsWithCondition =
    //           item.name.toLowerCase().startsWith(value.toLowerCase()) ||
    //           item.category.toLowerCase().startsWith(value.toLowerCase()) ||
    //           item.price.toLowerCase().startsWith(value.toLowerCase()) ||
    //           item.order_status.toLowerCase().startsWith(value.toLowerCase())

    //         let includesCondition =
    //           item.name.toLowerCase().includes(value.toLowerCase()) ||
    //           item.category.toLowerCase().includes(value.toLowerCase()) ||
    //           item.price.toLowerCase().includes(value.toLowerCase()) ||
    //           item.order_status.toLowerCase().includes(value.toLowerCase())

    //         if (startsWithCondition) {
    //           return startsWithCondition
    //         } else if (!startsWithCondition && includesCondition) {
    //           return includesCondition
    //         } else return null
    //       })
    //       .slice(state.params.page - 1, state.params.limit)
    //     return { ...state, filteredData }
    //   } else {
    //     filteredData = state.data
    //     return { ...state, filteredData }
    //   }
    // case "ADD_DATA":
    //   let id = state.data.slice(-1)[0].id + 1
    //   state.data.push({
    //     ...action.obj,
    //     id,
    //     popularity: determinePopularity(action.obj.popularity)
    //   })
    //   moveIndex(
    //     state.data,
    //     state.data.findIndex(item => item.id === id),
    //     0
    //   )
    //   return {
    //     ...state,
    //     data: state.data,
    //     totalRecords: state.allData.length,
    //     sortIndex: getIndex(state.allData, state.data, state.sortIndex)
    //   }
    // case "UPDATE_DATA":
    //   state.data.find(item => {
    //     if (item.id === action.obj.id) {
    //       let popularity = determinePopularity(action.obj.popularity.popValue)
    //       return Object.assign(item, { ...action.obj, popularity })
    //     } else {
    //       return item
    //     }
    //   })
    //   return { ...state }
    // case "DELETE_DATA":
    //   let index = state.data.findIndex(item => item.id === action.obj.id)
    //   let updatedData = [...state.data]
    //   updatedData.splice(index, 1)
    //   return {
    //     ...state,
    //     data: updatedData,
    //     totalRecords: state.allData.length,
    //     sortIndex: getIndex(
    //       state.allData,
    //       state.data,
    //       state.sortIndex,
    //       state.params
    //     )
    //   }
    case 'GET_OFFER_ID':
      return {
        ...state,
        id: action.id,
      };
    case 'GET_OFFER_DETAIL':
      return {
        ...state,
        offer_details: action.offer_details,
        offer_price:action.offer_price
      };
    default:
      return state;
  }
};

export default DataListReducer;
