import axios from 'axios';
import setAuthToken from '../../../utility/setAuthToken';
import { DeviceUUID } from 'device-uuid';
import Resturl from '../../../configs/url'
import { history } from '../../../history';
import md5 from 'md5';
import sha256 from 'sha256';
import { notification } from "../../../utility/notification";
import {
  GET_ERRORS,
  USER_LOADING
} from '../../reducers/auth/loginReducer';
import { getModel } from '../vehicle';

const instance = axios.create();
// Add your interceptors here
instance.interceptors.request.use((config) => {
  console.log("instance.interceptors.request", config)


});
instance.interceptors.response.use((response) => {
  console.log("instance.interceptors.response", response)
});

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post('', userData)
    .then(res => history.push('/login'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginUser = userData => async dispatch => {
  var du = new DeviceUUID().parse();
  var dua = [
    du.language,
    du.os,
    du.cpuCores,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
  ];
  console.log("dua", dua)
  var uuid = du.hashMD5(dua.join(':'));

  const obj = {
    email: userData.email,
    password: sha256(md5(userData.password)),
  };


  let headers = {
    timeout: 20000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'device_id': uuid,
      'device_type': 'WEB',
      'device_name': du.browser,
      'app_type': 'Partner',
      'device_version': du.version,
      'os_name': du.os,
      'app_version': 1
    }
  };

  axios.post(Resturl.login_url,
    obj,
    headers,
  )
    .then(result => {
      console.log("45456456456", result)
      if (result.data.status === 0) {
        notification("Login", result.data.message, "danger")
        dispatch({
          type: "LOGIN_WITH_ERROR",
          payload: result
        });
      }
      else {
        const user = {
          name: result.data.name,
          email: result.data.email,
          user_type: result.data.user_type
        }
        console.log("here")
        const token = result.data.oauth_token;
        const partnerId = result.data.user_id;
        const locationID = result.data.location_id
        const UserID = result.data.user_id
        // console.log("sadasdas", )
        // localStorage.setItem("OnboardingOpen", true);
        // localStorage.setItem("OnboardingOpentoken", token);
        // localStorage.setItem("partnerId", partnerId);
        // localStorage.setItem("Oauth", token);
        localStorage.setItem("LocationID", locationID);
        localStorage.setItem("user_id", UserID);
        localStorage.setItem("User", JSON.stringify(user))
        // Set token to Auth header
        setAuthToken(token);
        if (result.data.is_profile_completed == false) {
          localStorage.setItem("OnboardingOpen", true);
          localStorage.setItem("OnboardingOpentoken", token);
          localStorage.setItem("partnerId", partnerId);
          dispatch({
            type: "LOGIN_WITH_ONBORDING",
            payload: result
          });
        } else {
          localStorage.setItem("Oauth", token);
          dispatch({
            type: "LOGIN_WITH_EMAIL",
            payload: result
          });
        }
        dispatch(getModel)
      }
    })
    .catch(error => {

      dispatch({
        type: "LOGIN_WITH_ERRORS",
        payload: error
      });
    });
}
/*const result = await axios.post(
  'http://34.241.252.172:3000/login',
  userData,
  headers,
  {responseType: 'json'}
);
console.log("after "+result)
try {
  console.log("here",result);
  if(result.data.status===0){
  dispatch({
    type: "LOGIN_WITH_ERROR",
    payload: result
  });}
  else{
    dispatch({
      type: "LOGIN_WITH_EMAIL",
      payload: result
    });

}
  
} catch (error) {
  console.log(error.response.status);
  dispatch({
    type: "LOGIN_WITH_ERRORS",
    payload: error.response.data
  });
}
};*/

// Set logged in user
export const setCurrentUser = decoded => async dispatch => {
  // var uuid = new DeviceUUID().get();
  // var du = new DeviceUUID().parse();
  var du = new DeviceUUID().parse();
  var dua = [
    du.language,
    du.os,
    du.cpuCores,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
  ];
  console.log("dua", dua)
  var uuid = du.hashMD5(dua.join(':'));
  let headers = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      'device_id': uuid,
      'device_type': 'WEB',
      'device_name': du.browser,
      'app_type': 'Partner',
      'device_version': du.version,
      'os_name': du.os,
      'app_version': 1,
      'oauth': decoded
    }
  };
  // dispatch({
  //              type: "LOGIN_WITH_EMAIL",
  //              payload: {data:{user_type:"PARTNER"}}
  //          });

  await axios.post(Resturl.validate_auth,
    headers,
  )

    .then(result => {

      if (result.data.status === 0) {

        dispatch({
          type: "LOGIN_WITH_ERROR",
          payload: result
        });
        // notification("Session",result.data.message.toString(),"danger")
        //dispatch(logoutUser());
      }
      else if (result.data.status === 401) {
        // notification("Logout",result.data.message.toString(),"danger")
        // dispatch(logoutUser());
      }
      else {
        const user = {
          name: result.data.name,
          email: result.data.email,
          user_type: result.data.user_type
        }
        console.log("here")
        const token = result.data.oauth_token;
        const partnerId = result.data.user_id;
        const locationID = result.data.location_id
        // localStorage.setItem("Oauth", token);
        localStorage.setItem("LocationID", locationID);
        localStorage.setItem("user_id", result.data.user_id);
        localStorage.setItem("User", JSON.stringify(user))
        // Set token to Auth heade
        setAuthToken(token);
        if (result.data.is_profile_completed == false) {
          localStorage.setItem("OnboardingOpen", true);
          localStorage.setItem("OnboardingOpentoken", token);
          localStorage.setItem("partnerId", partnerId);
          dispatch({
            type: "LOGIN_WITH_ONBORDING",
            payload: result
          });
        } else {
          localStorage.setItem("Oauth", token);
          dispatch({
            type: "LOGIN_WITH_EMAIL",
            payload: result
          });
        }
        // dispatch({
        //   type: "LOGIN_WITH_EMAIL",
        //   payload: result
        // });
        dispatch(getModel())
      }
    })
    .catch(error => {
      // if(error.response.data.status===401){
      //   dispatch(logoutUser());
      // }
      dispatch({
        type: "LOGIN_WITH_ERRORS",
        payload: error
      });
    });



};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => async dispatch => {
  // Remove token from local storage
  localStorage.removeItem('Oauth');
  localStorage.removeItem('LocationId');
  localStorage.removeItem('OnboardingOpen');
  localStorage.removeItem('OnboardingOpentoken');
  localStorage.removeItem('partnerId');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  // await dispatch(setCurrentUser({}));

  history.push('./login');
};
