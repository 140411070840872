
const userRole = val => {
  console.log(val.user_type);
  const a=[];
  if(val.user_type==="PARTNER"){
    a.push("Provider","Dispatcher","Driver","Accountant");
    return a
  }
  
  if(val.permission.provider){
    a.push("Provider");
  }
  if(val.permission.dispatcher){
    a.push("Dispatcher");
  }
  if(val.permission.driver){
    a.push("Driver");
  }
  if(val.permission.accountant){
    a.push("Accountant");
  }
  return a
};

export const login = (state = {isAuthenticated:false }, action) => {
  console.log("ddddd9999", action.type)
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return {
        ...state,
        userRole: userRole(action.payload.data),
        values: action.payload.data,
        isAuthenticated: true,
      };
    }
    case "LOGIN_WITH_ONBORDING": {
      return {
        ...state,
        userRole: userRole(action.payload.data),
        values: action.payload.data,
        isAuthenticatedOnbording: true,
        isAuthenticated: true,
      };
    }
    case "LOGIN_WITH_ERROR":{
      return { ...state, errors: action.payload.data.message }
    }
    // case "LOGIN_CLEAN_ERROR":{
    //   return { ...state, errors: action.payload }
    // }
    // case "LOGIN_WITH_FB": {
    //   return { ...state, values: action.payload }
    // }
    // case "LOGIN_WITH_TWITTER": {
    //   return { ...state, values: action.payload }
    // }
    // case "LOGIN_WITH_GOOGLE": {
    //   return { ...state, values: action.payload }
    // }
    // case "LOGIN_WITH_GITHUB": {
    //   return { ...state, values: action.payload }
    // }
    // case "LOGIN_WITH_JWT": {
    //   return { ...state, values: action.payload }
    // }
    // case "LOGOUT_WITH_JWT": {
    //   return { ...state, values: action.payload }
    // }
    // case "LOGOUT_WITH_FIREBASE": {
    //   return { ...state, values: action.payload }
    // }
    // case "CHANGE_ROLE": {
    //   return { ...state, userRole: action.userRole }
    // }
    default: {
      return state
    }
  }
}
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT = "LOGOUT";


const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  isAuthenticatedOnbording: false,
  user: {},
  loading: false,
  errors:{}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
      case GET_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    default:
      return state;
  }
}
