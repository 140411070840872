import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import driver from "./driver/"
import vehicle from "./vehicle/"
import rating from "./rating/"
import offer from "./offer/"
import planned from "./planned/"
import finished from "./finished/"
import loading from "./loading/"
import dashboard from "./dashboard"
import dataList from './data-list'

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  driver: driver,
  vehicle:vehicle,
  offer:offer,
  planned:planned,
  finished:finished,
  dashboard:dashboard,
  rating:rating,
  loading:loading,
  dataList:dataList
  
})

export default rootReducer
