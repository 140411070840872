import React, { Component,useRef } from 'react';
import { Label, Input, FormGroup, Progress } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { X } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Dropzone from 'react-dropzone';
import 'dropzone/dist/dropzone.css';
import * as Yup from 'yup';
import axios from 'axios';
import Resturl from '../../../configs/url';
import { connect } from 'react-redux';
//import '../../../assets/scss/custom.css';
import Spinner from '../../../components/@vuexy/spinner/Loading-spinner';
import {
  addVehicle,
  deleteData,
  getData,
  updateVehicle
} from '../../../redux/actions/vehicle';
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from 'react-bootstrap-button-loader';

const SignUpSchema = Yup.object().shape({
  model_name: Yup.string().required('Model Required.'),
//  productionYear: Yup.string().required('Production year Required.'),
 // color: Yup.string().required('Color Required.'),
  licensePlate: Yup.string().required('License plate Required.'),
  serviceClass: Yup.string().required('Service class Required.'),
  vehicle_insurance_certificate: Yup.string().required(
    'Vehicle insurance certificate Required.'
  ),
  vehicle_license: Yup.string().required('Vehicle license Required.')
});

class DataListSidebar extends Component {
  formikRef = React.createRef(); // Create a ref for the Formik component
  state = {
    id: '',
    vehicle_license: '',
    vehicle_insurance_certificate: '',
    vehicle_license_id: '',
    vehicle_insurance_certificate_id: '',
    vehicle_license_bar: 0,
    vehicle_insurance_certificate_bar: 0,
    model_name: '',
    color: '',
    service_class: '',
    defaultAlert: false,
    confirmAlert: false,
    cancelAlert: false,
    isOpen: false,
    loading:false,
    service_class_id:"",
vehicle_model_id:"",
location_service_class_id:"",
location_vehicle_model_id:""
  };
  addNew = false;
  componentWillUpdate(){
    console.log(this.state)
  }
  componentDidUpdate(prevProps, prevState) {

    console.log(this.state)
    
    if (this.props.vehicle_detail !== '' && prevProps.vehicle_detail === '') {
      if (this.props.vehicle_detail._id !== prevState.id) {
        this.setState({ id: this.props.vehicle_detail._id });
      }
      if (this.props.vehicle_detail.model !== prevState.model_name) {
        this.setState({ model_name: this.props.vehicle_detail.model });
      }
      if (this.props.vehicle_detail.service_class !== prevState.service_class) {
        this.setState({ service_class: this.props.vehicle_detail.service_class });
      }
      if (this.props.vehicle_detail.year !== prevState.productionYear) {
        this.setState({ productionYear: this.props.vehicle_detail.year });
      }
      if (this.props.vehicle_detail.color !== prevState.color) {
        this.setState({ color: this.props.vehicle_detail.color });
      }
      if (this.props.vehicle_detail.plate_number !== prevState.licensePlate) {
        this.setState({ licensePlate: this.props.vehicle_detail.plate_number });
      }
      if (this.props.vehicle_detail.documents[0].file_type !== prevState.vehicle_license_id) {
        this.setState({ vehicle_license_id: 'vehicle' });
      }
      if (this.props.vehicle_detail.documents[1].file_type !== prevState.vehicle_license_id) {
        this.setState({ vehicle_insurance_certificate_id: 'vehicle' });
      }
    }
    if (this.props.vehicle_detail === '' && prevProps.vehicle_detail !== '') {
      this.setState({
        model_name: '',
        productionYear: '',
        color: '',
        licensePlate: '',
        service_class: '',
        vehicle_license_id: '',
        vehicle_insurance_certificate_id: ''
      });
    }
    if (this.addNew) {
      this.setState({
        model_name: '',
        productionYear: '',
        color: '',
        licensePlate: '',
        service_class: '',
        vehicle_license_id: '',
        vehicle_insurance_certificate_id: ''
      });
    }
    this.addNew = false;
  }

  _handleImageChange = (e, type) => {
    // e.preventDefault();

    // FileList to Array
    let files = Array.from(e.target.files);

    // File Reader for Each file and and update state arrays
    files.forEach((file, i) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        if (type === 'license') {
          this.setState({
            vehicle_license_img: reader.result
          });
        } else if (type === 'certificate') {
          this.setState({
            vehicle_insurance_certificate_img: reader.result
          });
        }
      };

      reader.readAsDataURL(file);
    });
  };

  onDrop = async (image, reject, event) => {
    console.log(event.target);
    // const name=event.target.name_image
    //var binary = instanceOfFile.getAsBinary
    //console.log('event', event.target);
    // await this.setState({
    //   [event.target.dataset.attribute]: URL.createObjectURL(image[0])
    // });

    const formData = new FormData();
    formData.append('file', image[0]);
    // formData.append("type", "driver");
    axios
      .post(Resturl.uploadimage, formData, {
        onUploadProgress: progressEvent => {
          this.setState({
            [`${event.target.dataset.attribute}_bar`]: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          });
          console.log(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }
      })
      .then(response => {
        this.setState({
          [`${event.target.dataset.attribute}_id`]: response.data.file_id,
          [`${event.target.dataset.attribute}`]: image[0].name
        });
      })
      .catch(e => {
       // toast.error('Something went wrong, try again later');
      });
  };
  handleSubmit = obj => {
    this.setState({
      loading:true
    })
    if (this.props.vehicle_detail == '') {
      this.addNew = true;
      this.props.addVehicle(obj);
      this.props.handleSidebar(false, true);

    } else {
      obj.vehicle_id = this.props.id;
      this.props.updateVehicle(obj);
      this.props.handleSidebar(false, true);
      console.log('vehicle details', this.props.vehicle_detail);
    }

    this.setState({
      vehicle_license: '',
      vehicle_insurance_certificate: '',
      vehicle_license_id: '',
      vehicle_insurance_certificate_id: '',
      vehicle_license_bar: 0,
      vehicle_insurance_certificate_bar: 0,
      model_name: '',
      service_class: '',
      productionYear: '',
      color: '',
      licensePlate: '',
      loading:false
    });

  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };
  removeImage = event => {
    // console.log(event.target.dataset.attribute);
    this.setState({
      [event.target.dataset.attribute]: '',
      [`${event.target.dataset.attribute}_id`]: '',
      [`${event.target.dataset.attribute}_bar`]: 0
    });
  };
  upload = async event => {
    console.log(event.target);
    event.persist();
    // await this.setState({
    //   [event.target.dataset.attribute]: URL.createObjectURL(image[0])
    // });
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    // formData.append("type", "driver");
    axios
      .post(Resturl.uploadimage, formData, {
        onUploadProgress: progressEvent => {
          this.setState({
            [`${event.target.name}_bar`]: Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          });
        }
      })
      .then(response => {
        this.setState({
          [`${event.target.name}_id`]: response.data.file_id
        });
      })
      .catch(e => {
        //console.log(e);
      });
  };

  handleChange = async event => {
    console.log(event.target.name);
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
    const service_class = (await (this.props.vehicle_model &&
      this.state.model_name !== ''))
      ? this.props.vehicle_model.find(
          model => model.name === this.state.model_name
        ).service_class
      : '';
       const service_class_id = (await (this.props.vehicle_model &&
      this.state.model_name !== ''))
      ? this.props.vehicle_model.find(
          model => model.name === this.state.model_name
        ).service_class_id
      : '';
      const vehicle_model_id = (await (this.props.vehicle_model &&
        this.state.model_name !== ''))
        ? this.props.vehicle_model.find(
            model => model.name === this.state.model_name
          ).vehicle_model_id
        : '';
        const location_service_class_id = (await (this.props.vehicle_model &&
          this.state.model_name !== ''))
          ? this.props.vehicle_model.find(
              model => model.name === this.state.model_name
            ).location_service_class_id
          : '';
          const location_vehicle_model_id = (await (this.props.vehicle_model &&
            this.state.model_name !== ''))
            ? this.props.vehicle_model.find(
                model => model.name === this.state.model_name
              ).location_vehicle_model_id
            : '';
    await this.setState({
      service_class: service_class,
      service_class_id:service_class_id,
      vehicle_model_id:vehicle_model_id,
      location_service_class_id:location_service_class_id,
      location_vehicle_model_id:location_vehicle_model_id
    });
  };
  resetErrors = setErrors => {
    setErrors({});
  };

  
  resetform (){
    return this.formikRef?.current?.resetForm()
  }

  render() {
    let { show, handleSidebar, data, vehicle_model,vehicle_detail    } = this.props;
    // this condition for reset form when sidebar is clase
    if (!this.props?.show) {
      this.resetform()
    }

    
    return (
      <React.Fragment>
{console.log('this.props.id',this.props.id)}
        <Formik
          innerRef={this.formikRef} // Assign the ref to the Formik component
          initialValues={{
            model_name: this.props.id ? vehicle_detail.model:'',
            productionYear: this.props.id ? this.state.productionYear:'',
            color: this.props.id ? this.state.color :'',
            // licensePlate: this.state.licensePlate,
            licensePlate: this.props.id ? vehicle_detail?.plate_number:'',
            serviceClass:  this.props.id ? vehicle_detail?.service_class:'',
            vehicle_license: this.props.id ? vehicle_detail?.documents?.[0]?._id:'',
                        // vehicle_license: this.state.vehicle_license_id,
            // vehicle_license_filePath: vehicle_detail?.documents?.[0]?.file_path,
            
            vehicle_insurance_certificate:this.props.id ? vehicle_detail?.documents?.[1]?._id:''
            // vehicle_insurance_certificate: this.state
            //   .vehicle_insurance_certificate_id
          }}
          validationSchema={SignUpSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
           
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            console.log('values.serviceClass', values);
            if(this.props.id){
              const editdata={}

              if (!(this.state.model_name === this.props.vehicle_detail.model)) {
                editdata.model=values.model
              }
              if (!(this.state.productionYear === this.props.vehicle_detail.year)) {
                editdata.year=values.productionYear
              }
             
              if (!(this.state.color=== this.props.vehicle_detail.color)) {
                editdata.color=values.color
              }
              if (!(this.state.licensePlate=== this.props.vehicle_detail.plate_number)) {
                editdata.plate_number=values.licensePlate
              }
              if (!(this.state.service_class=== this.props.vehicle_detail.service_class)) {
                editdata.service_class=values.serviceClass
              }
              if(this.state.vehicle_license_id!=='vehicle'||this.state.vehicle_insurance_certificate_id!=='vehicle'){
                console.log("in docs");
                editdata.doc=[]
              }
              if(this.state.vehicle_license_id!=='vehicle'){
               
                editdata.doc.push({
                  type: 'vehicle_license',
                  file_id: this.state.vehicle_license_id
                })
              }
              if(this.state.vehicle_insurance_certificate_id!=='vehicle'){
               
                editdata.doc.push({
                  type: 'vehicle_insurance',
                  file_id: this.state.vehicle_insurance_certificate_id
                })
              }
              // if(this.state.driver_practice_permit_img_id!==''){
              //   editData.doc.push({
              //     type: 'driver_practice_permit',
              //     file_id: this.state.driver_practice_permit_img_id
              //     // exp_date: this.state.driver_practice_permit_date
              //   })
              // }



              // values = {
              //   model: values.model,
              //   year: values.productionYear,
              //   color: values.color,
              //   plate_number: values.licensePlate,
              //   service_class: this.state.service_class
              // };
              this.handleSubmit(editdata);
            }
            else{
              values = {
                model: values.model,
                year: values.productionYear,
                color: values.color,
                plate_number: values.licensePlate,
                service_class: this.state.service_class,
                service_class_id: this.state.service_class_id,
                vehicle_model_id: this.state.vehicle_model_id,
                location_service_class_id: this.state.location_service_class_id,
                location_vehicle_model_id: this.state.location_vehicle_model_id,

                docs: [
                  {
                    file_type: 'vehicle_license',
                    file_id: this.state.vehicle_license_id
                  },
                  {
                    file_type: 'vehicle_insurance',
                    file_id: this.state.vehicle_insurance_certificate_id
                  }
                ]
              };
              this.handleSubmit(values);
              
            }







            

           // this.handleSubmit(values);

            // Simulate submitting to database, shows us values submitted, resets form
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
              resetForm();
            //   setSubmitting(false);
            // }, 500);
          }}
          render={({
            errors,
            touched,
            values,
            setFieldValue,
            isSubmitting,
            handleChange,
            handleBlur,
            setErrors,
            resetForm
          }) => (
            <Form>
              {console.log('values',values)}
              <div
                className={classnames('data-list-sidebar', {
                  show: show
                })}
              >
                {this.props.loading ? (
                  <Spinner />
                ) : (
                  <React.Fragment>
                    <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
                      <h4>
                        {this.props.id !== ''
                          ? 'UPDATE VEHICLE'
                          : 'ADD NEW VEHICLE'}
                      </h4>
                      <X
                        size={20}
                        onClick={() => {
                          this.resetErrors(setErrors);
                          handleSidebar(false, true);
                        }}
                      />
                    </div>
                    <PerfectScrollbar
                      className="data-list-fields px-2 mt-3"
                      options={{ wheelPropagation: false }}
                    >
                      <FormGroup>
                        <Label for="data-type">Model</Label>
                        <Input
                          type="select"
                          name="model_name"
                          placeholder="Black"
                          value={values.model_name}
                          onChange={e => {
                            // console.log('eeeeeee',e?.target?.value)
                            setFieldValue('model_name',e?.target?.value)
                            // this.handleChange(e);
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className={`form-control ${
                            errors.model && touched.model ? 'is-invalid' : ''
                          }`}
                        >
                          <option value="" label="Select a Model" />
                          {vehicle_model
                            ? vehicle_model.map(model => (
                                <option key={model.name} value={model.name}>
                                  {model.name}
                                </option>
                              ))
                            : ''}
                        </Input>
                        <ErrorMessage
                          name="model_name"
                          component="label"
                          className="text-danger text-sm"
                        />
                      </FormGroup>
                      {/* <FormGroup>
                        <Label for="data-type">Production year</Label>
                        <Input
                          type="select"
                          name="productionYear"
                          placeholder="Black"
                          value={values.productionYear}
                          onChange={e => {
                            this.handleChange(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className={`form-control ${
                            errors.productionYear && touched.productionYear
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Select a Production year" />
                          <option value="2012">2012</option>
                          <option value="2014">2014</option>
                        </Input>
                        <ErrorMessage
                          name="productionYear"
                          component="label"
                          className="text-danger text-sm"
                        />
                      </FormGroup>
                       */}
                      {/* <FormGroup>
                        <Label for="data-type">Color</Label>
                        <Input
                          type="select"
                          name="color"
                          placeholder="Black"
                          value={values.color}
                          onChange={e => {
                            this.handleChange(e);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className={`form-control ${
                            errors.color && touched.color ? 'is-invalid' : ''
                          }`}
                        >
                          <option value="" label="Select a color" />
                          <option value="Black">Black</option>
                          <option value="White">White</option>
                        </Input>
                        <ErrorMessage
                          name="color"
                          component="label"
                          className="text-danger text-sm"
                        />
                      </FormGroup>
                     
                      */}
                      <FormGroup>
                        <Label for="data-type">License Plate</Label>
                        <Field
                          type="text"
                          name="licensePlate"
                          placeholder="RJ19CA5365"
                          onChange={e => {
                            this.handleChange(e);
                            handleChange(e);
                          }}
                          className={`form-control ${
                            errors.licensePlate && touched.licensePlate
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          name="licensePlate"
                          component="label"
                          className="text-danger text-sm"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="data-type">Service Class</Label>
                        <Field
                          type="text"
                          name="serviceClass"
                          disabled
                          onBlur={handleBlur}
                          value={
                            vehicle_detail?.service_class
                            // vehicle_model && this.state.model_name !== ''
                            //   ? vehicle_model.find(
                            //       model => model.name === this.state.model_name
                            //     ).service_class
                            //   : ''
                          }
                          // placeholder="business class"
                          className={`form-control ${
                            errors.serviceClass && touched.serviceClass
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        {}
                        <ErrorMessage
                          name="serviceClass"
                          component="label"
                          className="text-danger text-sm"
                        />
                      </FormGroup>
                      <Label for="data-email">Vehicle license</Label>
                      <FormGroup>
                        {this.props.id ? (
                          <div className="">
                            <img
                              alt="Licence"
                              className="img-fluid w-25"
                              src={vehicle_detail?.documents?.[0]?.file_path}
                              // src="https://cdn0.iconfinder.com/data/icons/driving-school-basic-lineal/512/08_License-512.png"
                            />

                            <label
                              className="mr-1 ml-1 btn btn-outline-primary"
                              htmlFor="update-image1"
                              color="primary"
                            >
                              <a className="ml-2">Change</a>
                              {/* <Upload
                                  size={15}
                                  className="text-center ml-2"
                                /> */}
                              <input
                                type="file"
                                id="update-image1"
                                name="vehicle_license"
                                hidden
                                onChange={e => {
                                  this.upload(e);
                                }}
                              />
                            </label>
                            {this.state.vehicle_license_bar === 100 ||
                            this.state.vehicle_license_bar === 0 ? (
                              ''
                            ) : (
                              <Progress
                                className="col-6"
                                striped
                                value={this.state.vehicle_license_bar}
                              />
                            )}
                          </div>
                        ) : (
                          <React.Fragment>
                            <Dropzone onDrop={this.onDrop} multiple={false}>
                              {({ getRootProps, getInputProps }) => (
                                <React.Fragment>
                                  <section>
                                    <div
                                      {...getRootProps({
                                        className: 'dropzone col-6',
                                        'data-attribute': 'vehicle_license'
                                      })}
                                    >
                                      <input
                                        {...getInputProps({
                                          name: 'vehicle_license',
                                          'data-attribute': 'vehicle_license'
                                        })}
                                      />
                                      <p data-attribute="vehicle_license">
                                        Drag 'n' drop some files here, or click
                                        to select files
                                      </p>

                                      {this.state.vehicle_license_bar === 100 ||
                                      this.state.vehicle_license_bar === 0 ? (
                                        ''
                                      ) : (
                                        <Progress
                                          className="mt-4"
                                          striped
                                          value={this.state.vehicle_license_bar}
                                        />
                                      )}
                                    </div>
                                  </section>
                                  {this.state.vehicle_license ? (
                                    <section className="col-6">
                                      <button
                                        type="button"
                                        className="close position-absolute r-0"
                                        aria-label="Close"
                                        onClick={e => {
                                          this.removeImage(e);
                                        }}
                                      >
                                        <span
                                          data-attribute="vehicle_license"
                                          aria-hidden="true"
                                        >
                                          &times;
                                        </span>
                                      </button>
                                      {this.state.vehicle_license_bar === 100 ||
                                      this.state.vehicle_license_bar === 0 ? (
                                        ''
                                      ) : (
                                        <Progress
                                          className="mt-4"
                                          striped
                                          value={this.state.vehicle_license_bar}
                                        />
                                      )}
                                    </section>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              )}
                            </Dropzone>
                            <ErrorMessage
                              name="vehicle_license"
                              component="label"
                              className="text-danger text-sm"
                            />
                          </React.Fragment>
                        )}

                        <FormGroup className="col-5 row">
                          <Label for="data-name">
                            {this.state.vehicle_license}
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      <Label for="data-email">
                        Vehicle Insurance Certificate
                      </Label>
                      <FormGroup>
                        {this.props.id ? (
                          <div className="">
                            <img
                              alt="Licence"
                              className="img-fluid w-25"
                              
                              src={vehicle_detail?.documents?.[1]?.file_path}
                              // src="https://cdn0.iconfinder.com/data/icons/driving-school-basic-lineal/512/08_License-512.png"
                            />

                            <label
                              className="mr-1 ml-1 btn btn-outline-primary"
                              htmlFor="update-image"
                              color="primary"
                            >
                              <a className="ml-2">Change</a>
                              {/* <Upload
                                  size={15}
                                  className="text-center ml-2"
                                /> */}
                              <input
                                type="file"
                                id="update-image"
                                name="vehicle_insurance_certificate"
                                hidden
                                onChange={e => {
                                  this.upload(e);
                                }}
                              />
                            </label>
                            {this.state.vehicle_insurance_certificate_bar ===
                              100 ||
                            this.state.vehicle_insurance_certificate_bar ===
                              0 ? (
                              ''
                            ) : (
                              <Progress
                                className="col-6"
                                striped
                                value={
                                  this.state.vehicle_insurance_certificate_bar
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <React.Fragment>
                            <Dropzone onDrop={this.onDrop} multiple={false}>
                              {({ getRootProps, getInputProps }) => (
                                <React.Fragment>
                                  <section>
                                    <div
                                      {...getRootProps({
                                        className: 'dropzone col-6',
                                        'data-attribute':
                                          'vehicle_insurance_certificate'
                                      })}
                                    >
                                      <input
                                        {...getInputProps({
                                          name: 'vehicle_insurance_certificate',
                                          'data-attribute':
                                            'vehicle_insurance_certificate'
                                        })}
                                      />
                                      <p data-attribute="vehicle_insurance_certificate">
                                        Drag 'n' drop some files here, or click
                                        to select files
                                      </p>
                                      {this.state
                                        .vehicle_insurance_certificate_bar ===
                                        100 ||
                                      this.state
                                        .vehicle_insurance_certificate_bar ===
                                        0 ? (
                                        ''
                                      ) : (
                                        <Progress
                                          className="mt-4"
                                          striped
                                          value={
                                            this.state
                                              .vehicle_insurance_certificate_bar
                                          }
                                        />
                                      )}
                                    </div>
                                  </section>
                                  {this.state.vehicle_insurance_certificate ? (
                                    <section className="col-6">
                                      <button
                                        type="button"
                                        className="close position-absolute remove-file"
                                        aria-label="Close"
                                        onClick={e => {
                                          this.removeImage(e);
                                          setFieldValue(
                                            'vehicle_insurance_certificate',
                                            ''
                                          );
                                        }}
                                      >
                                        <span
                                          data-attribute="vehicle_insurance_certificate"
                                          aria-hidden="true"
                                        >
                                          &times;
                                        </span>
                                      </button>
                                    </section>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              )}
                            </Dropzone>
                            <ErrorMessage
                              name="vehicle_insurance_certificate"
                              component="label"
                              className="text-danger text-sm"
                            />
                          </React.Fragment>
                        )}

                        <FormGroup className="col-5 row">
                          <Label for="data-name">
                            {this.state.vehicle_insurance_certificate}
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </PerfectScrollbar>
                    <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
                     {console.log('this.state.loading',this.state.loading,isSubmitting)}
                      <Button
                        color="primary"
                        // onClick={() => this.handleSubmit(this.state)}
                        type="submit"
                        disabled={isSubmitting}
                        loading={this.state.loading} 
                      >
                        {this.props.id !== '' ? 'Update' : 'Submit'}
                      </Button>
                      {this.props.id != '' ? (
                        <Button
                          className="ml-1"
                          color="danger"
                          outline
                          onClick={() => {
                            this.resetErrors(setErrors);
                            this.setState({ defaultAlert: true });
                            // handleSidebar(false, true);
                          }}
                        >
                          Delete
                        </Button>
                      ) : (
                        ''
                      )}
                      <Button
                        className="ml-1"
                        color="danger"
                        outline
                        type='button'
                        onClick={() => {
                          this.resetErrors(setErrors);
                          resetForm();
                          handleSidebar(false, true);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>{' '}
                  </React.Fragment>
                )}
              </div>
            </Form>
          )}
        />
        <SweetAlert
          title="Are you sure?"
          warning
          show={this.state.defaultAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          onConfirm={() => {
            this.handleAlert('basicAlert', false);
            this.props.deleteData(this.props.id);
            this.handleAlert('confirmAlert', true);
            handleSidebar(false, true);
          }}
          onCancel={() => {
            this.handleAlert('basicAlert', false);
            this.handleAlert('cancelAlert', true);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>

        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="success"
          show={this.state.confirmAlert}
          onConfirm={() => {
            this.handleAlert('defaultAlert', false);
            this.handleAlert('confirmAlert', false);
            this.props.getData();
          }}
        >
          <p className="sweet-alert-text">Your vehicle has been deleted.</p>
        </SweetAlert>

        <SweetAlert
          error
          title="Cancelled"
          confirmBtnBsStyle="success"
          show={this.state.cancelAlert}
          onConfirm={() => {
            this.handleAlert('defaultAlert', false);
            this.handleAlert('cancelAlert', false);
          }}
        >
          <p className="sweet-alert-text">Your vehicle is safe :)</p>
        </SweetAlert>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  console.log('statestate123465',state)
  return {
    vehicle_model: state.vehicle.vehicle_model,
    id: state.vehicle.id,
    vehicle_detail: state.vehicle.vehicle_detail,
    loading: state.loading.loadingSidebar
  };
};

export default connect(mapStateToProps, {
  addVehicle,
  deleteData,
  getData,
  updateVehicle
})(DataListSidebar);
