import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
   {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    // icon: <Icon.Home size={20} />,
    permissions: ["Provider","Dispatcher","Accountant"],
    navLink: "/",
  },
  {
    id: "rides",
    title: "Rides",
    type: "item",
    // icon: <Icon.File size={20} />,
    permissions: ["Provider","Accountant"],
    navLink: "/offers", 
    activeurl: ["/planned", "/finished"]
  },
  // {
  //   id: "drivers",
  //   title: "Drivers",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: [ "Dispatcher","Accountant"],
  //   navLink: "/drivers"
  // },
  {
    id: "drivers",
    title: "Chauffeurs",
    type: "item",
    // icon: <Icon.File size={20} />,
    permissions: [ "Dispatcher","Accountant"],
    activeurl: ["/chauffeurs/add", ],
    activeurlid:["chauffeur-details"],
    navLink: "/chauffeurs"
  },
  {
    id: "vehicles",
    title: "Vehicles",
    type: "item",
    // icon: <Icon.File size={20} />,
    permissions: ["Dispatcher","Accountant"],
    activeurl: ["/vehicles/add"],
    activeurlid:["vihicle-details"],
    navLink: "/vehicles"
  },
  {
    id: "Company",
    title: "Company",
    type: "item",
    // icon: <Icon.File size={20} />,
    permissions: ["Dispatcher","Accountant"],
    navLink: "/company"
  },
  {
    id: "rating",
    title: "Rating",
    type: "item",
    // icon: <Icon.File size={20} />,
    permissions: ["Provider","Dispatcher","Accountant"],
    navLink: "/ratings"
  },
  {
    id: "Reports",
    title: "Reports",
    type: "item",
    // icon: <Icon.File size={20} />,
    permissions: ["Provider","Dispatcher","Accountant"],
    navLink: "/reports"
  },
]

export default horizontalMenuConfig
