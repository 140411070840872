const initialState = {
  loading:false,
  loadingSidebar:true,
  loadingButton:false
}


const Loading = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_TRUE":
      return {
        loading:true
      }
      case "LOADING_FALSE":
      return {
        loading:false
      }
      case "LOADING_SIDEBAR_TRUE":
      return {
        loadingSidebar:true
      }
      case "LOADING_SIDEBAR_FALSE":
      return {
        loadingSidebar:false
      }
      case "LOADING_BUTTON_TRUE":
      return {
        loadingButton:true
      }
      case "LOADING_BUTTON_FALSE":
      return {
        loadingButton:false
      }
   
    default:
      return state
  }
}

export default Loading
