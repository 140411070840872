const initialState = {
  data: [],
  available_vehicles:[],
  vehicle_model:null,
  vehicle_detail:"",
  params: null,
  totalPages: 0,
  totalRecords: 0,
  start:0,
  end:0,
  id:'',
  search_data:'',
  heading:'',
  sub_heading:''
}
const availableVehicle=val=>{
  const result=[];
  result.push( {value: "Unassigned", label: "Unassigned", color: "#FFFFFF", isFixed: true ,disabled: 'yes'});
  val.map(i=>{
    result.push({value:`${i._id}`,label:`${i.plate_number} `, color:"#FFFFFF",isFixed:true})
  })
  
  return result

}

const getModel =val=>{
  const models=[];
  val.map(index=>{
    index.allow_model.map(allow=>{
      models.push({
        name:allow,
        service_class:index.name
      })
      
    })
  })
  return models
}
// const determinePopularity = val => {
//   if (val >= 75) return { popValue: val, color: "success" }
//   else if (val < 75 && val >= 55) return { popValue: val, color: "primary" }
//   else if (val < 55 && val >= 35) return { popValue: val, color: "warning" }
//   else if (val < 35 && val >= 0) return { popValue: val, color: "danger" }
//   else return { popValue: 0, color: "danger" }
// }

// const moveIndex = (arr, from, to) => {
//   let el = arr[from]
//   arr.splice(from, 1)
//   arr.splice(to, 0, el)
// }

// const getIndex = (arr, arr2, arr3, params = {}) => {
//   if (arr2.length > 0) {
//     let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
//     let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
//     let finalArr = [startIndex, endIndex]
//     return (arr3 = finalArr)
//   } else {
//     let finalArr = [arr.length - parseInt(params.limit), arr.length]
//     return (arr3 = finalArr)
//   }
// }

const DataListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VEHICLE":
      return {
        ...state,
        data: action.data,
        totalPages: action.totalPages,
        totalPages: action.totalPages,
        totalRecords:action.totalRecords,
        params: action.params,
        start:action.start,
        end:action.end,
        heading:action.heading,
        sub_heading:action.sub_heading
      }
      case "GET_AVAILABLE_VEHICLE":
      return {
        ...state,
        available_vehicles:availableVehicle(action.data)
      }
      case "VEHICLE_STATUS_LOADER":
        return{
          ...state,
          loading_id:action.payload.id,
          loading:action.payload.loading
        }
    case "GET_MODEL":
      return {
        ...state,
        vehicle_model: action.model
      }
      case "FILTER_VEHICLE":
        return {
          ...state,
          data: action.data,
          totalPages: action.totalPages,
          totalRecords:action.totalRecords,
          params: action.params,
          start:action.start,
          end:action.end,
          heading:action.heading,
          sub_heading:action.sub_heading
        }
    // case "FILTER_DATA":
    //   let value = action.value
    //   let filteredData = []
    //   if (value.length) {
    //     filteredData = state.allData
    //       .filter(item => {
    //         let startsWithCondition =
    //           item.name.toLowerCase().startsWith(value.toLowerCase()) ||
    //           item.category.toLowerCase().startsWith(value.toLowerCase()) ||
    //           item.price.toLowerCase().startsWith(value.toLowerCase()) ||
    //           item.order_status.toLowerCase().startsWith(value.toLowerCase())

    //         let includesCondition =
    //           item.name.toLowerCase().includes(value.toLowerCase()) ||
    //           item.category.toLowerCase().includes(value.toLowerCase()) ||
    //           item.price.toLowerCase().includes(value.toLowerCase()) ||
    //           item.order_status.toLowerCase().includes(value.toLowerCase())

    //         if (startsWithCondition) {
    //           return startsWithCondition
    //         } else if (!startsWithCondition && includesCondition) {
    //           return includesCondition
    //         } else return null
    //       })
    //       .slice(state.params.page - 1, state.params.limit)
    //     return { ...state, filteredData }
    //   } else {
    //     filteredData = state.data
    //     return { ...state, filteredData }
    //   }
    case "ADD_VEHICLE":
      return {
        ...state,
        // data: state.data,
        // totalRecords: state.allData.length,
      }
   
    case "DELETE_DATA":
      // let index = state.data.findIndex(item => item.id === action.obj.id)
      // let updatedData = [...state.data]
      // updatedData.splice(index, 1)
      return {
        ...state,
        //data: updatedData,
        totalRecords: state.allData.length,
        // sortIndex: getIndex(
        //   state.allData,
        //   state.data,
        //   state.sortIndex,
        //   state.params
        // )
      }
      case "GET_VEHICLE_ID":
        return {
          ...state,
          id:action.id,
          state:true,
          vehicle_detail:action.vehicle_detail
        }
    default:
      return state
  }
}

export default DataListReducer
