import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["Provider","Dispatcher","Accountant"],
    navLink: "/",
  },
  
  {
    id: "rides",
    title: "Rides",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["Provider","Accountant"],
    navLink: "/offers"
  },
  {
    id: "drivers",
    title: "Drivers",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: [ "Dispatcher","Accountant"],
    navLink: "/drivers"
  },
  {
    id: "vehicles",
    title: "Vehicles",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["Dispatcher","Accountant"],
    navLink: "/vehicles"
  },
  {
    id: "rating",
    title: "Rating",
    type: "item",
    icon: <Icon.Star size={20} />,
    permissions: ["Provider","Dispatcher","Accountant"],
    navLink: "/ratings"
  },

];

export default navigationConfig;
