import Resturl from '../../../configs/url';

import axios from 'axios';
import { loadingTrue, loadingFalse } from '../loading';
import { notification } from '../../../utility/notification';

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params) => {
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 4,
      search_data:""
    };
  }
  return async (dispatch) => {
    console.log("8888888666", params)
    await axios
      .post(Resturl.driver_list, params)
      .then((response) => {
        if (response.data.status === 401) {
          localStorage.removeItem('Oauth');
          window.location.href = '/';
        }
        dispatch({
          type: 'GET_DRIVER',
          data: response.data.drivers,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_driver,
          start: response.data.start,
          end: response.data.end,
          heading: response.data.message,
          sub_heading: response.data.message,
          params,
        });
        dispatch({ type: 'DRIVER_STATUS_LOADER',payload:{id:'',loading:false} });
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          notification("Driver","Server not respond.Please try again.","danger")
        }
      });
  };
};
export const availableDriver = () => {
  return async (dispatch, getState) => {
    await axios
      .post(Resturl.available_driver)
      .then((response) => {
        console.log(response);
        dispatch({
          type: 'GET_AVAILABLE_DRIVER',
          data: response.data.drivers,
        });
      })
      .catch((error) => {});
  };
};

export const changeStatus = (value) => {
  console.log(value);

  return async (dispatch, getState) => {
    dispatch({ type: 'DRIVER_STATUS_LOADER',payload:{id:value.id,loading:true} });
    if (value.working_status === 'ACTIVE') {
      await axios
        .post(Resturl.driver_status, {
          driver_id: value.id,
          account_status: 'DEACTIVATED',
        })
        .then((response) => {
          notification("Driver",response.data.message,"success")
          dispatch({ type: 'DRIVER_STATUS_DEACTIVATED' });
          dispatch(getData(getState().driver.params));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await axios
        .post(Resturl.driver_status, {
          driver_id: value.id,
          account_status: 'ACTIVE',
        })
        .then((response) => {
          notification("Driver",response.data.message,"success")
          dispatch({ type: 'DRIVER_STATUS_ACTIVE' });
          dispatch(getData(getState().driver.params));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const getInitialData = () => {
  return async (dispatch) => {
    await axios
      .post(Resturl.driver_list, { limit: 4, page: 1 })
      .then((response) => {
        dispatch({ type: 'GET_ALL_DATA', data: response.data.drivers });
      });
  };
};

export const filterData = (value) => {
  let params = {
    page: 1,
    limit: 4,
    search_data: value,
  };
  return async (dispatch) => {
    await axios.post(Resturl.driver_list, params).then((response) => {
      dispatch({
        type: 'FILTER_DRIVER',
        data: response.data.drivers,
        totalPages: response.data.total_page,
        totalRecords: response.data.total_driver,
        start: response.data.start,
        end: response.data.end,
        heading: response.data.message,
        sub_heading: response.data.message,
        params,
      });
    });
  };
};

export const deleteData = (obj) => {
  return (dispatch) => {
    axios
      .post(Resturl.driver_delete, {
        driver_id: obj,
      })
      .then((response) => {
        if (response.data.status == 1) {
          notification("Driver",response.data.message,"success")
        } else {
          notification("Driver",response.data.message,"danger")
        }
        dispatch({ type: 'DELETE_DRIVER', obj });
      });
  };
};

export const updateData = (obj) => {
  return (dispatch, getState) => {
    axios
      .post('/api/datalist/update-data', {
        obj,
      })
      .then((response) => {
        dispatch({ type: 'UPDATE_DATA', obj });
      });
  };
};

export const addData = (obj) => {
  return (dispatch, getState) => {
    let params = getState().dataList.params;
    axios
      .post('/api/datalist/add-data', {
        obj,
      })
      .then((response) => {
        dispatch({ type: 'ADD_DATA', obj });
        dispatch(getData(params));
      });
  };
};
export const getId = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'GET_DRIVER_ID',
      id: id,
    });
  };
};
